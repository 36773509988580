<template>
	<div class="detailsMass-item">
		<p class="toBack" @click="toBack" v-if="showToBack"><i class="el-icon-back"></i> 返回</p>
		<div class="headerClass" v-if="testData.title == '健康体检表'">
			<p class="title">检查项目</p>
			<p class="rightContent">
				<span>体检日期：{{ testObj.dateTime || '--' }}</span
				><span>责任医生：{{ testObj.name || '--' }}</span>
			</p>
		</div>
		<div class="headerClass" v-if="testData.title == '会诊记录表' || testData.title == '接诊记录表'">
			<p class="title">{{ testData.title || '' }}</p>
			<p class="rightContent">
				<span>{{ testData.title.slice(0, 2) + '日期' }}：{{ testObj.dateTime || '--' }}</span>
				<span>责任医生：{{ testObj.name || '--' }}</span>
			</p>
		</div>
		<p class="title" v-if="testData.title != '健康体检表' && testData.title != '会诊记录表' && testData.title != '接诊记录表'">
			{{ testData.title || '' }}
		</p>
		<div class="detailsMass-item-iter" v-if="testData.items && testData.items.length > 0">
			<div class="iter clearfix">
				<div
					v-for="(item, index) in testData.items"
					:key="index"
					:class="!item.items ? 'otherData clearfix' : item.items ? 'threeData clearfix' : ''"
				>
					<p class="float-l gridClass" :style="{ height: item.height + 'px', overflow: 'hidden', justifyContent: 'center' }">
						{{ item.title }}
					</p>
					<div class="threeData-item float-l clearfix" v-if="item.items && item.items.length > 0">
						<div v-for="(subItem, subIndex) in item.items" :key="subIndex">
							<div v-if="subItem.title == '症状'">
								<p style="width: 100%">{{ subItem.value && subItem.value.length != 0 ? subItem.value.join(',') : '' }}</p>
							</div>
							<div v-else>
								<p
									class="float-l gridClass"
									:style="{
										height: subItem.height + 'px',
									}"
								>
									{{ subItem.title }}
								</p>
								<div class="threeData-item-item float-l" v-if="subItem.items && subItem.items.length != 0">
									<div class="clearfix" v-for="(child, childIndex) in subItem.items" :key="childIndex">
										<p
											class="float-l gridClass"
											:style="{
												width: '50%',
												height: child.height + 'px',
											}"
										>
											{{ child.title }}
										</p>
										<div class="threeData-item-item-item float-l" v-if="child.items && child.items.length != 0">
											<p v-for="(subchild, subchildIndex) in child.items" :key="subchildIndex">
												{{ child.title == '毒物种类' ? subchild.title + ' / ' + subchild.value : subchild.title }}
											</p>
										</div>
										<div class="threeData-item-item-item float-l" v-else>
											<p style="width: 100%">
												{{ child.prefix && child.suffix ? child.prefix + child.value + child.suffix : child.value && child.value.toString() }}
											</p>
										</div>
									</div>
								</div>
								<div class="threeData-item-item float-l" v-else>
									<p style="width: 100%">{{ subItem.value && subItem.value.toString() }}</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="nullData" v-else>暂无数据</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			testData: {},
			testObj: {},
		};
	},
	props: {
		detailsData: {
			type: Object,
			default: () => {},
		},
		showToBack: {
			type: Boolean,
			default: true,
		},
	},
	watch: {
		detailsData: {
			handler(val) {
				let newData = JSON.parse(JSON.stringify(val));
				if (val.items[0].title == '体检日期') {
					let obj = {
						dateTime: newData.items[0]?.items[0]?.value?.toString(),
						name: newData.items[1]?.items[0]?.value?.toString(),
					};
					this.testObj = obj;
					newData = {
						items: newData.items.slice(2),
						title: val.title,
					};
				}
				if (val.items[0].items[0].title == '会诊日期' || val.items[0].items[0].title == '接诊日期') {
					let obj = {
						dateTime: newData.items[0]?.items[0]?.value?.toString(),
						name: newData.items[0]?.items[1]?.value?.toString(),
					};
					this.testObj = obj;
					newData = {
						items: [{ items: newData.items[0].items.slice(2), title: val.items[0].title }],
						title: val.title,
					};
				}
				this.getDetails(newData);
			},
			deep: true,
			immediate: true,
		},
	},
	methods: {
		getDetails(data) {
			let newData = JSON.parse(JSON.stringify(data));
			const singleHeight = 48;
			handleItemHeight(newData);
			function handleItemHeight(objItem) {
				if (!objItem) return 0;
				if (!objItem.items || objItem.items.length === 0) {
					objItem.height = singleHeight;
					return singleHeight;
				}
				objItem.height = 0;
				objItem.items.map((item) => {
					let childHeight = handleItemHeight(item);
					objItem.items.height = childHeight;
					objItem.height += childHeight;
				});
				return objItem.height;
			}
			this.testData = JSON.parse(JSON.stringify(newData));
		},
		toBack() {
			this.$emit('toBack');
		},
	},
};
</script>

<style lang="scss" scoped>
.detailsMass-item {
	padding: 0 40px;
	margin-bottom: 40px;
	.toBack {
		width: 68px;
		color: #333;
		background-color: #f5f5f5;
		text-align: center;
		font-size: 12px;
		height: 28px;
		line-height: 28px;
		margin-right: 28px;
		border-radius: 4px;
		margin-bottom: 32px;
		cursor: pointer;
		.el-icon-back {
			margin-right: 8px;
		}
	}
	.headerClass {
		display: flex;
		justify-content: space-between;
		align-items: center;
		.rightContent {
			color: #666666;
			font-size: 12px;
			span {
				margin-right: 16px;
				&:last-child {
					margin-right: 0;
				}
			}
		}
	}
	.title {
		font-size: 16px;
		color: #333333;
		padding-left: 8px;
		border-left: 3px solid #1db9b1;
		line-height: 16px;
		margin-bottom: 16px;
	}
	&-iter {
		position: relative;
		border-right: 1px solid rgba(214, 217, 224, 0.8);
		border-bottom: 1px solid rgba(214, 217, 224, 0.8);
		img {
			position: absolute;
			right: 0;
			top: 0;
			width: 148px;
			height: 192px;
		}
		.iter {
			width: calc(100% - 164px);
			p {
				color: #333;
				padding: 0 16px;
				line-height: 48px;
				height: 48px;
				border: 1px solid rgba(214, 217, 224, 0.8);
				width: calc(50% - 165px);
				border-bottom: none;
				border-right: none;
				&:nth-child(2n-1) {
					width: 165px;
					background-color: #f4f6fd;
					border-right: none;
				}
			}
			.otherData {
				width: calc(100% + 164px);
				p {
					width: calc(100% - 165px);
					&:first-child {
						width: 165px;
						background-color: #f4f6fd;
					}
				}
			}
			.threeData {
				width: calc(100% + 164px);
				&-item {
					width: calc(100% - 165px);
					p {
						width: calc(50% - 154px);
						background-color: #fff;
					}
					&-item {
						width: calc(100% - calc(50% - 154px));
						p {
							width: 50%;
							background-color: #fff;
						}
						&-item {
							width: 50%;
							p {
								width: 100%;
								background-color: #fff;
							}
						}
					}
				}
			}
		}
	}
	.nullData {
		text-align: center;
		color: #999999;
		font-size: 20px;
		margin-top: 40px;
	}
	.gridClass {
		display: grid;
		align-items: center;
		// justify-content: center;
		line-height: 22px !important;
	}
}
</style>